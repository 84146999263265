@import "~@/erp/styles/variables/variables.scss";



























































































.wrapper-status {
  margin-top: 10px;
}
::v-deep {
  // .upload-images {
  //   .el-upload-list {
  //     .is-success {
  //       .el-upload-list__item-actions {
  //         .el-upload-list__item-delete {
  //           display: none !important;
  //         }
  //       }
  //     }
  //   }
  // }
}
