@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-container {
  padding-bottom: 0 !important;

  .purchase-content {
    padding: 0 20px 0;

    h4 {
      font-weight: 600;
      font-size: 14px;
      margin: 20px 0 10px 0;
    }

    .column {
      height: 32px;
      margin: 20px 0;
      justify-content: flex-start;

      h4 {
        margin: 0;
        line-height: 32px;
      }
    }

    .el-input,
    .el-select {
      width: 100%;
    }
  }

  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }

  .account-num {
    span {
      font-size: 16px;
      margin-right: 5px;
      font-weight: 600;
    }

    .el-input {
      width: 130px;
    }

    .el-input-number {
      width: 100px;
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
    z-index: 100;
  }

  /deep/.el-table table th.star div:before {
    content: '*';
    color: red;
  }

  /deep/ .el-table__body tr,
  .el-table__body td {
    height: 90px;
  }

  .product-table {
    /deep/ {
      .cell {
        overflow: initial;

        .el-form-item__content {
          padding: 5px 0;

          .el-form-item__error {
            // top: 42px;
          }
        }
      }
    }
  }

  .wrapper-box {
    .wrapper-add {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .logoInfoBox {
    position: relative;

    .shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 999;
    }
  }

  .split-style {
    position: relative;
    padding-right: 15px;

    em {
      position: absolute;
      top: 2px;
      right: 0;
      z-index: 999;
      cursor: pointer;
    }
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;

    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }

    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }
}

.discount-sty {
  position: relative;
  text-align: left;

  .el-button {
    position: absolute;
    top: 8px;
    right: -5px;
    z-index: 2;
    height: 28px;
    width: 28px;
    margin: 0;

    /deep/ i {
      font-size: 16px;
      margin: -3px;
    }
  }
}

.info-wrapper {
  text-align: left;
  width: 200px;

  p {
    width: 100%;
    padding: 2px 5px;
    white-space: break-spaces;
  }

  i {
    color: #f56c6c;
    font-size: 18px;
    cursor: pointer;
  }
}

.pay-tips {
  font-size: 12px;
  color: red;
  position: absolute;
  left: 100px;
  top: 32px;
  line-height: 18px;
}
