@import "~@/erp/styles/variables/variables.scss";















































































































































































































.custom-dialog {
  .detail-style .line .item {
    min-width: 180px;
    em {
      padding: 10px 0 5px;
    }
  }
  .detail-style .line:last-child .item {
    min-width: 360px;
    p {
      padding: 0;
      font-size: 16px;
      color: #000;
    }
    p.small {
      font-size: 12px;
      color: #666;
    }
  }
  h4 {
    margin: 0 0 10px;
  }
  p {
    font-size: 16px;
    padding: 0 0 10px;
    line-height: 24px;
    color: #000;
  }
}
